import { AuthProvider } from './util/auth';
import { QueryClientProvider } from './util/db';
import { Routes, Route, Router } from "./util/router";
import {ThemeProvider } from './components/theme-provider';
import Navbar from './components/Navbar';
import IndexPage from './pages/IndexPage';
import AuthPage from './pages/AuthPage';
import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';


function App() {
  return (
    <QueryClientProvider>
      <AuthProvider>
      <ThemeProvider>
        <Router>
          <div className="h-screen overflow-y-auto">
            <Navbar bgColor="bg-vbg" />

            <Routes>
              <Route path="/" element={<IndexPage/>} />
              <Route path="/auth/:type" element={<AuthPage/>} />
              <Route path="/dashboard" element={<DashboardPage/>} />
              <Route path="/settings" element={<SettingsPage/>} />
            </Routes>
          </div>
        </Router>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>

  );
}

export default App;
